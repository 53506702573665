import './related-teaser-slider.scss';

import Swiper, { Navigation, Pagination } from 'swiper';

class RelatedTeaserSlider {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-related-teaser',
            sliderAttr: 'slider',
            slideAttr: 'slide',
            gridAttr: 'data-grid'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$relatedTeaser = $element;
        this.$relatedTeaserContainer = this.$relatedTeaser.querySelector(`[${this.settings.initAttr}="${this.settings.sliderAttr}"]`);
        this.$relatedTeaserSlides = this.$relatedTeaser.querySelectorAll(`[${this.settings.initAttr}="${this.settings.slideAttr}"]`);
        this.relatedTeaserSlider = null;

        this.isGrid = this.$relatedTeaser.getAttribute(this.settings.gridAttr);

        if (this.$relatedTeaserContainer && this.$relatedTeaserSlides.length > 1) {
            this.initialize();
        }
    }

    initialize () {
        this.checkSize();
        this.setEvents();
    }

    checkSize () {
        if ((window.mediaMatchHandler[1024].matches && this.$relatedTeaserSlides.length <= 3) || (window.mediaMatchHandler[1024].matches && this.isGrid !== null)) {
            if (this.relatedTeaserSlider !== null) {
                this.destroySlider();
            }
        } else if (window.mediaMatchHandler[600].matches && this.$relatedTeaserSlides.length <= 2) {
            if (this.relatedTeaserSlider !== null) {
                this.destroySlider();
            }
        } else if (window.mediaMatchHandler[320].matches && this.$relatedTeaserSlides.length < 2) {
            if (this.relatedTeaserSlider !== null) {
                this.destroySlider();
            }
        } else {
            if (this.relatedTeaserSlider === null) {
                this.initSlider();
            }
        }
    }

    setEvents () {
        [1024, 600, 320].forEach((bp) => {
            window.mediaMatchHandler[bp].addEventListener('change', () => {
                this.checkSize();
            });
        });
    }

    initSlider () {
        this.relatedTeaserSlider = new Swiper(this.$relatedTeaserContainer, {
            modules: [Navigation, Pagination],
            speed: 500,
            slidesPerView: 'auto',
            centeredSlides: true,
            centeredSlidesBounds: true,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next'
            }
        });
    }

    destroySlider () {
        if (this.relatedTeaserSlider !== null) {
            this.relatedTeaserSlider.destroy(true, true);
            this.relatedTeaserSlider = null;
        }
    }
}

export { RelatedTeaserSlider };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$relatedTeaser = $context.querySelectorAll('[data-related-teaser="root"]');
        for (let i = 0; i < $$relatedTeaser.length; i++) {
            const $relatedTeaser = $$relatedTeaser[i];

            $relatedTeaser.API = new RelatedTeaserSlider($relatedTeaser);
        }
    }
});
